import React from "react";
import img_2 from "../../../assets/img/banner/img_2.jpg";
import { useTranslation } from "react-i18next";
function Network() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section
        className="networkSec"
        style={{
          background: `url(${img_2})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="networkItem networkItem1">
            <div className="fisherman-content">
              <h3>{t("Our Network")} </h3>
            </div>
            <ul className="namedd">
              <li>
                <div className="networkNumber1">
                  <h2>1 Thousand</h2>
                  <p>{t("Customers")}</p>
                </div>
              </li>
              <li>
                <div className="networkNumber1">
                  <h2>50 Hundred</h2>
                  <p>{t("Products")}</p>
                </div>
              </li>
              <li>
                <div className="networkNumber1">
                  <h2>20 Hundred</h2>
                  <p>{t("Delivery Depot")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Network;
