import React from "react";
import touchBG from "../../../assets/img/banner/img_2.jpg";
import buttonBG from "../../../assets/img/team-v1-shape1.png";

const GetTouch = () => {
  return (
    <>
      <section
        className="getTouchSec"
        style={{ background: `url(${touchBG})`, backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3 className="text-white">Drop us a message for any query</h3>
                <p className="text-white">
                  If you have an idea, we would love to hear about it.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="getTouch">
                <form className="mb-0" action="#">
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-name">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact-name"
                      name="name"
                      required
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Your E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="contact-email"
                      name="email"
                      required
                      placeholder="Enter Email"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="contact-email"
                      name="phone"
                      required
                      placeholder="Phone number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-message">
                      Your Message
                    </label>
                    <textarea
                      cols={30}
                      rows={4}
                      id="contact-message"
                      className="form-control"
                      name="message"
                      required
                      placeholder="Enter Your Message"
                    />
                  </div>
                  <div className="form-footer mb-0">
                    <button
                      type="button"
                      style={{
                        background: `url(${buttonBG})`,
                        padding: "10px 26px",
                      }}
                      className="btn text-white"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6"></div>

          </div>
        </div>
      </section>
    </>
  );
};

export default GetTouch;
