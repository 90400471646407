import React from "react";

import a1 from '../../../assets/img//img_1.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="storyImg">
                <img src={a1} alt="KINGSON AGROW PVT. LTD" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="storyText">
                <h3>
                  ABOUT KINGSON <span>AGROW PVT. LTD</span>
                </h3>

                <p>
                  Kingson Agrow Pvt. Ltd. is sister concern company of Omkar
                  agri global Pvt. Ltd. it's a leading integrated agrochemical
                  company with defined strengthen manufacturing, marketing &
                  export of technical grade, various formulations & combinations
                  of agrochemicals globally. The manufacturing unit at Bhatinda,
                  Punjab & Muzaffarnagar, U.P (India) having fully equipped
                  automatic manufacturing facility integrated with in-house R &
                  D laboratory.
                </p>
                <p>
                  various formulations & combinations of agrochemicals globally.
                  The manufacturing unit at Bhatinda, Punjab & Muzaffarnagar,
                  U.P (India) having fully equipped automatic manufacturing
                  facility integrated with in-house R & D laboratory.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
