import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import home1 from "../../../assets/img/banner/Home-1-slider-img-05-1536x800.jpg";
import home2 from "../../../assets/img/banner/Home-1-slider-img-04-1536x800.jpg";
import home3 from "../../../assets/img/banner/Home-1-slider-img-02-1536x800.jpg";

import banner1 from "../../../assets/img/banner/img_1.jpg";
import banner2 from "../../../assets/img/banner/img_2.jpg";

import shape from "../../../assets/img/shape.png";
import buttonBG from "../../../assets/img/team-v1-shape1.png";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      console.log("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <section className="bannerSection">
        <Slider {...settings}>
          {data &&
            data.slice(0, 2).map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div className="bannerItem">
                      <img src={item?.image.url} className="img-fluid" />
                      <div className="bannerText">
                        <h2>kingson</h2>
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
        </Slider>
      </section> */}

      <section className="bannerSection">
      <Slider {...settings}>
        <div className="bannerItem">
          <img src={banner1} alt="Banner" className="img-fluid" />
          <div className="shape">
            <img src={shape} alt="shape" className="img-fluid" />
          </div>
          <div className="bannerDetail">
            <div className="bannerContent">
              <div className="title">
                <h6>find the boundaries. push through!</h6>
                <h3>
                  <strong>Best harvest</strong> in the World
                </h3>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Nesciunt at assumenda sequi consectetur cupiditate possimus
                  nihil voluptatem impedit.{" "}
                </p>
              </div>
              <div className="shopNow">
                <Link
                  to="/products"
                  className="buttonBG"
                  style={{ background: `url(${buttonBG})` }}
                >
                  shop now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bannerItem">
          <img src={banner2} alt="Banner" className="img-fluid" />
          <div className="shape">
            <img src={shape} alt="shape" className="img-fluid" />
          </div>
          <div className="bannerDetail">
            <div className="bannerContent">
              <div className="title">
                <h6>find the boundaries. push through!</h6>
                <h3>
                  <strong>"Life of crops".. </strong> "Pride of farmers"
                </h3>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Nesciunt at assumenda sequi consectetur cupiditate possimus
                  nihil voluptatem impedit.{" "}
                </p>
              </div>
              <div className="shopNow">
                <Link
                  to="/products"
                  className="buttonBG"
                  style={{ background: `url(${buttonBG})` }}
                >
                  shop now
                </Link>
              </div>
            </div>
          </div>
        </div>
        </Slider>
      </section>
    </>
  );
}

export default Banner;
