import React from "react";
import { BsArrowRight } from "react-icons/bs";
import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList">
        <div className="container">
          <div className="fisherman-content">
            <h6 className="brandName">Kingson</h6>
            <h3>special product offer </h3>
            <p>Kingson Agrow engages in the manufacturing, formulating and exporting of Agrochemicals – Insecticides, Fungicides, Herbicides, Plant Growth Regulators / Micro Nutrients. Kingson has evolved as a new advanced growing company in the agro-chemical industry of India as well as Abroad with experienced visionary true leadership and enthusiastic team on path of going globally.</p>
          </div>
          <div className="serviceItemInfo">
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiChestnutLeaf className="ruppee" />
              </div>
              <div className="serviceItemText">
                <h5>Field Crops</h5>
                <p>Field Crops</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiFruitBowl className="wallet" />
              </div>
              <div className="serviceItemText">
                <h5>Fastfire</h5>
                <p>Get up to 60% Off</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceItemIcon">
                <GiTomato className="mobile" />
              </div>
              <div className="serviceItemText">
                <h5>Cheston </h5>
                <p>on Cheston</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="serviceItem border-0">
              <div className="serviceItemIcon">
                <GiFertilizerBag className="offer" />
              </div>
              <div className="serviceItemText">
                <h5>fertilizer</h5>
                <p>Check Now</p>
                <Link to="/products">
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
