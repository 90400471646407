import React from "react";
import { Link } from "react-router-dom";
import net from "../../../assets/img/faq-bg1.png";
import buttonBG from "../../../assets/img/team-v1-shape1.png";
import shape2 from "../../../assets/img/shape-2.png";
import shape1 from "../../../assets/img/shape.png";

const Faq = () => {
  return (
    <>
      <section
        className="faqSec"
        style={{
          background: `url(${net})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="shape2">
          <img src={shape2} alt="Shape" className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="fisherman-content faq">
                <h6 className="brandName">frequently questions</h6>
                <h3>The question a lot time we get from our customer</h3>
                <Link
                  to="/"
                  className="buttonBG"
                  style={{ background: `url(${buttonBG})` }}
                >
                  Ask Yours
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordionFaq">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Why will we buy products from this company?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Kingson Agrow engages in the manufacturing, formulating
                        and exporting of Agrochemicals – Insecticides,
                        Fungicides, Herbicides, Plant Growth Regulators / Micro
                        Nutrients
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Is it possible to have natural products?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Kingson Agrow engages in the manufacturing, formulating
                        and exporting of Agrochemicals – Insecticides,
                        Fungicides, Herbicides, Plant Growth Regulators / Micro
                        Nutrients
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={shape1} alt="Shape" className="img-fluid" />
        </div>
      </section>
    </>
  );
};

export default Faq;
