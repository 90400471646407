import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import artboard from "../../../assets/img/brands/artboard.jpg";
import bayer from "../../../assets/img/brands/bayer.jpg";
import dhanuka from "../../../assets/img/brands/dhanuka.jpg";
import namdhari from "../../../assets/img/brands/namdhari.jpg";

import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";

const imgs = [etg, kynoch, falcon, oemff]

function Brands() {
  const { data, isLoading, error } = useListAllBrandQuery()

  return (
    <>
      <section className="brandsSec">
        <div className="container">

          <div className="brandsItem">
            <div className="brandsHeader">
              <div className="fisherman-content">
                <h6 className="brandName">Kingson</h6>
                <h3>top brands</h3>
              </div>
             
            </div>
            {isLoading && <div style={{ textAlign: "center" }}> <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div></div>}
            {error && <h5 style={{ textAlign: "center", color: "red" }}>Server Error</h5>}
            {/* <marquee behavior="scroll" direction="left"> */}
              <ul className="d-none">
                {data && data.map((item, i) => {
                  return <li key={item._id}>
                    <Link to={`/product/brand/${item._id}`}>
                      <img src={imgs[i]} alt="Brand" className="img-fluid" />
                    </Link>
                  </li>
                })}
              </ul>
            {/* </marquee> */}

            <div className="brandsItem">
            
            <ul>
              <li>
                <Link to={"/"}>
                  <img src={etg} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={falcon} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={kynoch} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={oemff} alt="Brand" title="Brand" />
                </Link>
              </li>

              <li>
                <Link to={"/"}>
                  <img src={artboard} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={bayer} alt="Brand" title="Brand" />
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <img src={dhanuka} alt="Brand" title="Brand" />
                </Link>
              </li>
              
            </ul>
          </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default Brands;
